const initialState = {
  theme: "dark-theme",
};

const themeReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_CURRENT_THEME":
      return { ...state, theme: action.payload };

    default:
      return state;
  }
};

export default themeReducer;
