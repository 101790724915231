import React from "react";
import "./header.scss";
import logo from "../../../assets/svg/logo.svg";
import { Link } from "react-router-dom";

const Header = () => {
  return (
    <div className="header padding-x">
      <div className="d-flex align-items-center justify-content-between">
        <Link to="/" className="logo">
          <img src={logo} alt="logo" />
        </Link>

        <div className="menu-item-wrap">
          <a
            href="https://docs.usdao.io/docs/"
            target="_blank"
            rel="noreferrer"
            className="menu-link"
          >
            Docs
          </a>
          <a
            href="https://app.usdao.io/#/app"
            target="_blank"
            rel="noreferrer"
            className="menu-link btn btn-gradient"
          >
            USE APP
          </a>
        </div>
      </div>
    </div>
  );
};

export default Header;
