import { createStore, combineReducers } from "redux";

import appReducer from "./reducer";
import themeReducer from "./themeReducer";
import headerReducer from "./headerReducer";

const reducer = combineReducers({
  appReducer: appReducer,
  themeReducer: themeReducer,
  headerReducer: headerReducer,
});

const store = createStore(reducer);

export default store;
