import React, { useState, useEffect } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import wbtc from "../../../../assets/img/wbtcLight.png";
import wbtcD from "../../../../assets/img/wbtcPng.png";
import swal from "sweetalert";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import {
  withdrawEth,
  collateralFn,
  liquidationReserve,
  liquidationPriceFn,
  totalDeptFn,
  withdrawCollValidation,
  getMaxWithdraw,
} from "../helper";
import { truncateToDecimals } from "../../../common/utils";

var cn = require("classnames");

const WithdrawEthCal = ({ withdrawEthVal, calcualtion, vaultDetails }) => {
  return (
    <div className="deposite-info">
      <div className="d-flex justify-content-between deposite-detail-wrap">
        <p>Receive</p>
        <p>{truncateToDecimals(withdrawEthVal, 6)} ETH</p>
      </div>

      <div className="d-flex justify-content-between deposite-detail-wrap">
        <p>
          Liquidation reserve
          {["top"].map((placement) => (
            <OverlayTrigger
              key={placement}
              placement={placement}
              overlay={
                <Tooltip id={`tooltip-${placement}`}>
                  200 USDAO reserve will be refunded/burnt once vault is closed
                  the owner
                </Tooltip>
              }
            >
              <button className="btn pt-0 pb-0 ps-2 pe-2">
                <FontAwesomeIcon className="tooltipIcon" icon={faCircleInfo} />
              </button>
            </OverlayTrigger>
          ))}
        </p>

        <p>{liquidationReserve} USDAO</p>
      </div>

      <div className="d-flex justify-content-between deposite-detail-wrap">
        <p>Total debt</p>

        <p>{truncateToDecimals(vaultDetails.debt)} USDAO</p>
      </div>
    </div>
  );
};

function WithdrawEth({
  refreshAllData,
  accountDetails,
  vaultDetails,
  setRatio,
  ratio,
}) {
  const [withdrawEthVal, setWithdrawEthVal] = useState();
  const [error, setError] = useState({});
  const [calcualtion, setCalculation] = useState({});
  const [disable, setDisable] = useState(false);
  const selector = useSelector((state) => state);
  let { theme = {} } = selector.themeReducer;

  document.onkeydown = function (e) {
    if (e.defaultPrevented) {
      return;
    }
    if (e.keyCode === 109 || e.keyCode === 189) {
      return false;
    }
  };

  const calculateRatio = async (withdrawEthVal) => {
    let { coll, debt } = vaultDetails;
    coll = parseFloat(coll) - parseFloat(withdrawEthVal || 0);
    let dynamicRatio = await collateralFn(coll.toString(), debt);
    setRatio(dynamicRatio);
  };

  useEffect(() => {
    (async function () {
      setRatio(vaultDetails.ratio);
    })();
  }, []);

  useEffect(() => {
    if (withdrawEthVal) {
      (async function () {
        await calculateRatio(withdrawEthVal);
        let liquidationPrice = await liquidationPriceFn(withdrawEthVal);
        let totalDept = await totalDeptFn(withdrawEthVal);
        setCalculation({
          liquidationPrice: liquidationPrice,
          totalDept: totalDept,
        });
      })();
    }
  }, [withdrawEthVal]);

  const withdrawEthChangeHandler = async (e) => {
    let { value } = e.target;
    let { coll, debt } = vaultDetails;
    if (value > parseFloat(coll)) {
      setError({
        maxError: "You can not withdraw more than extra deposite WBTC",
      });
    } else {
      setError({ maxError: "" });
    }
    setWithdrawEthVal(value);
  };

  const withdrawEthOnSubmit = async () => {
    setDisable(true);
    if (vaultDetails.recoveryModeCheck) {
      setDisable(false);
      return swal(
        "Withdrawal of WBTC is suspended as system has gone into Recover mode"
      );
    }
    if (await withdrawCollValidation(withdrawEthVal)) {
      setDisable(false);
      const el = document.createElement("div");
      el.innerHTML =
        "An operation that would result in Collateral ratio < 125% is not allowed. If your vault is showing Collateral ratio above 125, this could be because of redistributions (<a target='_blank' href='https://docs.usdao.io/usdao-v2/protocol/onvault-borrowing-and-staking-protocol/borrowing'>see docs</a> ). To view the true value please adjust the vault (do any transaction on vault page)";

      return swal({
        content: el,
      });
    }

    if (parseFloat(ratio) < 110) {
      setDisable(false);
      const el = document.createElement("div");
      el.innerHTML =
        "An operation that would result in Collateral ratio < 110% is not allowed. If your vault is showing Collateral ratio above 110, this could be because of redistributions (<a target='_blank' href='https://docs.usdao.io/usdao-v2/protocol/onvault-borrowing-and-staking-protocol/borrowing'>see docs</a> ). To view the true value please adjust the vault (do any transaction on vault page)";

      return swal({
        content: el,
      });
    }
    await withdrawEth(withdrawEthVal, refreshAllData, setDisable);
  };

  const maxHandler = async () => {
    if (ratio < 110) {
      setWithdrawEthVal(0);
    } else {
      let { coll, debt } = vaultDetails;
      const maxEth = await getMaxWithdraw(
        coll,
        debt,
        vaultDetails.recoveryModeCheck
      );
      setWithdrawEthVal(maxEth);
    }
  };

  return (
    <>
      {/* ====================== Input ===================== */}
      <div className="tab-box-wrap">
        <div className="tab-box-info d-flex justify-content-between align-items-center">
          <p>Withdraw</p>
          <p>
            Wallet:{" "}
            {accountDetails && accountDetails.etherBalance
              ? truncateToDecimals(accountDetails.etherBalance, 6)
              : "0.00"}
          </p>
        </div>

        <div className="tab-box-values d-flex justify-content-between align-items-center">
          <input
            type="number"
            placeholder="Enter amount"
            id="number"
            onChange={withdrawEthChangeHandler}
            value={withdrawEthVal}
            min="0.01"
            step="0.01"
          />
          <div className="d-flex">
            <div className="value-status" onClick={maxHandler}>
              <p>Max</p>
            </div>
            <img
              className="wbtcimg"
              src={theme === "dark-theme" ? wbtc : wbtcD}
              alt=""
            />
          </div>
        </div>
      </div>
      {error.maxError && <div className="error">{error.maxError}</div>}
      {/* ====================== Input ===================== */}

      {/* ====================== Button ===================== */}
      <div className="d-flex justify-content-center mb-5">
        <a
          onClick={withdrawEthOnSubmit}
          rel="noreferrer"
          className={cn(
            "menu-link btn btn-gradient",
            (!parseFloat(withdrawEthVal) || disable) && "disabled"
          )}
        >
          Confirm
        </a>
      </div>
      {/* ====================== Button ===================== */}

      {/* ====================== Info ===================== */}
      {!!withdrawEthVal && (
        <WithdrawEthCal
          withdrawEthVal={withdrawEthVal}
          calcualtion={calcualtion}
          vaultDetails={vaultDetails}
        />
      )}
      {/* ====================== Info ===================== */}
    </>
  );
}

export default WithdrawEth;
