import React, { useEffect, useState } from "react";
import "./leading.scss";
import "./modal.scss";
import logo from "../../../assets/img/frame_1618873091.svg";
import plush from "../../../assets/img/plush.svg";
import chainlink from "../../../assets/img/Chainlink.svg";
import ether from "../../../assets/img/Ether.svg";
import wrapped_bitcoin from "../../../assets/img/Wrapped Bitcoin.svg";
import { ThreeDots } from "react-loader-spinner";
import { useSelector } from "react-redux";
import {
  supplyUsdaoFunc,
  supplyLinkFunc,
  supplyEthFunc,
  supplyWbtcFunc,
  borrowUsdaoFunc,
  borrowWbtcFunc,
  borrowChainFunc,
  borrowEthFunc,
  balanceOfComet,
  lendingBalChainLink,
  lendingBalWbtc,
  lendingBalWeTH,
  borrowRate,
  supplyRate,
  collateralValue,
  borrowCapacity,
  borrowOfAvailable,
} from "../../blockchain/commonFunction";

const Leading = () => {
  const selector = useSelector((state) => state);
  let { accountDetails = {}, transactionLoader = false } = selector.appReducer;
  const [show, setShow] = useState(false);
  const [valueUsdao, setValueUsdao] = useState();
  const [amount, setAmount] = useState();
  const [loading, setLoading] = useState(false);
  const [cometBal, setComeBal] = useState();
  const [link, setLink] = useState();
  const [wbtc, setWbtc] = useState();
  const [weth, setWeth] = useState();
  const [borrow, setBorrow] = useState();
  const [supply, setSupply] = useState();
  const [collVal, setCollVal] = useState();
  const [borrowCap, setBorrowCap] = useState();
  const [borrowAva, setBorrowAva] = useState();

  useEffect(() => {
    (async () => {
      let balOfComet = await balanceOfComet();
      setComeBal(balOfComet);
      let colBalLink = await lendingBalChainLink();
      setLink(colBalLink);
      let colBalwbtc = await lendingBalWbtc();
      setWbtc(colBalwbtc);
      let colBalweth = await lendingBalWeTH();
      setWeth(colBalweth);
      let getBorrow = await borrowRate();
      setBorrow(getBorrow);
      let getSupply = await supplyRate();
      setSupply(getSupply);
      let col = await collateralValue();
      setCollVal(col);
      const borrow = await borrowCapacity();
      setBorrowCap(borrow);

      const borrowAvailable = await borrowOfAvailable();
      setBorrowAva(borrowAvailable);
    })();
  }, []);

  const supplyUsdao = () => {
    setShow(true);
    setValueUsdao("supply");
  };

  const supplyChain = () => {
    setShow(true);
    setValueUsdao("chain");
  };

  const supplyEth = () => {
    setShow(true);
    setValueUsdao("eth");
  };

  const supplyWbtc = () => {
    setShow(true);
    setValueUsdao("wbtc");
  };

  const borrowUsdao = () => {
    setShow(true);
    setValueUsdao("borrowusdao");
  };

  const borrowWbtc = () => {
    setShow(true);
    setValueUsdao("borrowwbtc");
  };

  const borrowChain = () => {
    setShow(true);
    setValueUsdao("borrowchain");
  };

  const borrowEth = () => {
    setShow(true);
    setValueUsdao("borroweth");
  };

  const onCloseButton = () => {
    setShow(false);
    setLoading(false);
    setAmount("");
  };

  const submitUsdao = async () => {
    if (valueUsdao === "supply") {
      setLoading(true);
      const getValueUsdao = await supplyUsdaoFunc(amount);
      if (getValueUsdao) {
        setLoading(false);
        setShow(false);
        setAmount("");
      }
    }
    if (valueUsdao === "chain") {
      setLoading(true);
      const getValueLink = await supplyLinkFunc(amount);
      if (getValueLink) {
        setLoading(false);
        setShow(false);
        setAmount("");
      } else {
        setLoading(false);
        setShow(false);
        setAmount("");
      }
    }
    if (valueUsdao === "eth") {
      setLoading(true);
      console.log("hello");
      const getValueEth = await supplyEthFunc(amount);
      if (getValueEth) {
        setLoading(false);
        setShow(false);
        setAmount("");
      } else {
        setLoading(false);
        setShow(false);
        setAmount("");
      }
    }
    // if (valueUsdao === "eth") {
    //   setLoading(true);
    //   const getValueEth = await supplyEthFunc(amount);
    //   if (getValueEth) {
    //     setLoading(false);
    //     setShow(false);
    //     setAmount("");
    //   } else {
    //     setLoading(false);
    //     setShow(false);
    //     setAmount("");
    //   }
    // }
    if (valueUsdao === "wbtc") {
      setLoading(true);
      const getValueWbtc = await supplyWbtcFunc(amount);
      if (getValueWbtc) {
        setLoading(false);
        setShow(false);
        setAmount("");
      } else {
        setLoading(false);
        setShow(false);
        setAmount("");
      }
    }
    if (valueUsdao === "borrowusdao") {
      setLoading(true);
      const getBorrowUsdao = await borrowUsdaoFunc(amount);
      if (getBorrowUsdao) {
        setLoading(false);
        setShow(false);
        setAmount("");
      }
    }
    if (valueUsdao === "borrowwbtc") {
      setLoading(true);
      const getBorrowEbtc = await borrowWbtcFunc(amount);
      if (getBorrowEbtc) {
        setLoading(false);
        setShow(false);
        setAmount("");
      }
    }
    if (valueUsdao === "borrowchain") {
      setLoading(true);
      const getBorrowChain = await borrowChainFunc(amount);
      if (getBorrowChain) {
        setLoading(false);
        setShow(false);
        setAmount("");
      }
    }
    if (valueUsdao === "borroweth") {
      setLoading(true);
      const getBorrowEth = await borrowEthFunc(amount);
      if (getBorrowEth) {
        setLoading(false);
        setShow(false);
        setAmount("");
      }
    }
  };

  return (
    <>
      <div className="parent-div">
        <div className="child1-div">
          <p>Balance</p>
          <div className="grandchild1">
            <img src={logo} alt="logo" />
            <p>{cometBal}</p>
          </div>
          <p>$&nbsp;1.00</p>
        </div>
        <div className="child2-div">
          <button onClick={() => supplyUsdao("supply")} className="child2-btn">
            <img src={plush} alt="icon" />
            Supply USDAO
          </button>
          <button onClick={() => borrowUsdao("borrow")} className="child2-btn">
            <img src={plush} alt="icon" />
            Borrow USDAO{" "}
          </button>
        </div>
      </div>

      <div className="second-parent">
        {/* Column -1 */}
        <div className="child-1">
          <div className="child-1-head">
            <p>Collateral Asset</p>
            <p>Protocol Balance</p>
          </div>
          <div className="child-2-head">
            <div className="child-2-head-1">
              <img src={chainlink} alt="icon" />
              <div className="child-name">
                <p>Chainlink</p>
                <p>LINK</p>
              </div>
            </div>
            <div className="child-2-head-2">
              <p>{link}</p>
              <button
                onClick={() => supplyChain("chain")}
                className="child-2-head-2-btn"
              >
                +
              </button>
              <button
                onClick={() => borrowChain("borrowchain")}
                className="child-2-head-2-btn"
              >
                -
              </button>
            </div>
          </div>

          <div className="child-2-head">
            <div className="child-2-head-1">
              <img src={ether} alt="icon" />
              <div className="child-name">
                <p>Ether</p>
                <p>ETH</p>
              </div>
            </div>
            <div className="child-2-head-2">
              <p>{weth}</p>
              <button
                onClick={() => supplyEth("eth")}
                className="child-2-head-2-btn"
              >
                +
              </button>
              <button
                onClick={() => borrowEth("borroweth")}
                className="child-2-head-2-btn"
              >
                -
              </button>
            </div>
          </div>

          <div className="child-2-head-last">
            <div className="child-2-head-1">
              <img src={wrapped_bitcoin} alt="icon" />
              <div className="child-name">
                <p>Wrapped Bitcoin</p>
                <p>WBTC</p>
              </div>
            </div>
            <div className="child-2-head-2">
              <p>{wbtc}</p>
              <button
                onClick={() => supplyWbtc("wbtc")}
                className="child-2-head-2-btn"
              >
                +
              </button>
              <button
                onClick={() => borrowWbtc("borrowwbtc")}
                className="child-2-head-2-btn"
              >
                -
              </button>
            </div>
          </div>
        </div>
        {/* Column -2 */}
        <div className="child-2">
          <div className="balance">
            <p>USDAO Wallet Balance</p>
            <div className="balance-child">
              <img src={logo} alt="icon" />
              <p>USDAO: {accountDetails.usmBalance}</p>
            </div>
          </div>
          <hr />
          <div class="parent-borrow">
            <div class="borrow">
              <p>Borrow APR</p>
              <p data-reflection="7.96%">{borrow}%</p>
            </div>
            <div class="supply">
              <p>Supply APR</p>
              <p data-reflection="6.72%">{supply}%</p>
            </div>
          </div>
        </div>
        <div className="child-3">
          <p className="child-3-title">Position Summary</p>
          <div className="positon">
            <p>Collateral Value</p>
            <p>{collVal} USDAO</p>
          </div>
          <div className="positon">
            <p>Liquidation Point</p>
            <p>0.0000 USDAO</p>
          </div>
          <div className="positon">
            <p>Borrow Capacity</p>
            <p>{borrowCap} USDAO</p>
          </div>
          <div className="positon-last">
            <p>Available to Borrow</p>
            <p>{borrowAva} USDAO</p>
          </div>
        </div>
      </div>
      {show && (
        <>
          <div
            className="modal"
            tabIndex="-1"
            role="dialog"
            data-keyboard="false"
            data-backdrop="false"
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="row wrapper">
                  <div className="col-xl-11 col-md-11 col-sm-11 col-xs-11 mb-3">
                    <p>Lending borrowing</p>
                  </div>
                  <div
                    onClick={() => onCloseButton()}
                    className="closeButton col-xl-1 col-md-1 col-sm-1 col-xs-1 mb-3"
                  >
                    <p>X</p>
                  </div>
                  <hr />
                </div>
                {loading ? (
                  <ThreeDots type="" color="#3f9cb5" height="100" width="100" />
                ) : (
                  <>
                    <div className="modalBody">
                      <>
                        <p>Enter amount</p>
                      </>
                    </div>
                    <div className="Follow">
                      <div className="followfSm">
                        <div className="Sminput">
                          <div className="inputVal">
                            <input
                              value={amount}
                              className="inputfieldSm"
                              type="text"
                              placeholder="Enter"
                              onChange={(e) => setAmount(e.target.value)}
                            />
                          </div>
                          <div className="SubmitLink">
                            <button
                              onClick={() => submitUsdao()}
                              className="subbtn"
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Leading;
