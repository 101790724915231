import { ethers } from "ethers";
import swal from "sweetalert";
import {
  getChainId,
  getAddress,
  getMetaMask,
  writeContractFunction,
  readContractFunction,
  getContractAddress,
} from "../../blockchain/commonFunction";
import { contractDetails } from "../../blockchain/contractDetails";

const addCustomTokenMetamask = async (token, tokenName) => {
  const chainId = await getChainId();
  const tokenAddress = contractDetails[token].address[chainId];
  const tokenSymbol = tokenName;
  const tokenDecimals = 18;
  const tokenImage = "https://onvault.usdao.io/usm.png";

  try {
    // wasAdded is a boolean. Like any RPC method, an error may be thrown.
    const wasAdded = await window.ethereum.request({
      method: "wallet_watchAsset",
      params: {
        type: "ERC20", // Initially only supports ERC20, but eventually more!
        options: {
          address: tokenAddress, // The address that the token is at.
          symbol: tokenSymbol, // A ticker symbol or shorthand, up to 5 chars.
          decimals: tokenDecimals, // The number of decimals in the token
          image: tokenImage, // A string url of the token logo
        },
      },
    });

    if (wasAdded) {
      console.log("Thanks for your interest!");
    } else {
      console.log("Your loss!");
    }
  } catch (error) {
    console.log(error);
  }
};

const claimFn = async () => {
  let data;
  try {
    const contract = await writeContractFunction("borrowerOperation");
    data = await contract.claimCollateral();
  } catch (e) {
    if (e.code === 4001) {
      return swal("User denied transaction.");
    }
  }

  if (data) {
    const provider = await getMetaMask();
    provider.waitForTransaction(data.hash).then((res, err) => {
      if (res.status) {
        swal("Thanks For Trusting us.");
      } else {
        swal("Transaction Failed");
      }
    });
  }
};

const showClaimFn = async () => {
  const contract = await readContractFunction("collSurplus");
  const address = getAddress();
  const status = await contract.getCollateral(address);
  const formatStatus = ethers.utils.formatUnits(status, 8);
  return !!parseFloat(formatStatus);
};

const assetCirculationFn = async () => {
  const contract = await readContractFunction("fum");
  const contractAdd = await getContractAddress("community");
  const balance = await contract.balanceOf(contractAdd);
  const val = ethers.utils.parseUnits("2000000", 8);
  const val2 = ethers.utils.parseUnits("21000000", 8);
  const cal = val.sub(balance);
  const cal2 = val2.add(cal);
  const assetCirculation = ethers.utils.formatUnits(cal2, 8);
  return { assetCirculation };
};

export { addCustomTokenMetamask, claimFn, showClaimFn, assetCirculationFn };
