import { ethers } from "ethers";
import swal from "sweetalert";
import { contractDetails } from "./contractDetails";
import { getAccount } from "@wagmi/core";
import Web3 from "web3";

// const provider = new ethers.providers.JsonRpcProvider(
// 	'https://rinkeby.infura.io/v3/b001cbdee80c4e52806e2e072e601ce4'
// )
const bigNumber = 1.1579208923731621e61;

const getMetaMask = () => {
  const providerMetaMask = new ethers.providers.Web3Provider(window.ethereum);
  return providerMetaMask;
};

const getAccountDetails = async (setAlertMetaMaskLogOut) => {
  let { address } = getAccount();
  try {
    const details = {};
    const accounts = address;
    // const accounts = await window.ethereum.request({ //code for metamask connection
    //   method: "eth_requestAccounts",
    // });
    const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
    const networkName = await provider.getNetwork();
    const chainId = await window.ethereum.request({ method: "net_version" });
    const balanceCheckprovider = new ethers.Contract(
      contractDetails["wbtc"].address[chainId],
      contractDetails["wbtc"].abi,
      provider
    );
    const balance = await balanceCheckprovider.balanceOf(accounts);
    details.account = accounts;
    details.networkName = networkName.name;
    details.chainId = chainId;
    details.balance = ethers.utils.formatUnits(balance, 8);
    if (accounts.length) {
      return details;
    } else {
      console.log("ERROR IN  METAMASK CONNECTION");
    }
  } catch (error) {
    setAlertMetaMaskLogOut && setAlertMetaMaskLogOut(true);
    return false;
  }
};

const getChainId = async () => {
  try {
    const network = await getAccountDetails();
    return network.chainId;
  } catch (error) {
    console.log(error);
  }
};

const getNetworkName = async () => {
  try {
    const accountDetails = await getAccountDetails();
    return accountDetails.networkName;
  } catch (error) {
    console.log(error);
  }
};

const readContractFunction = async (token) => {
  const chainId = await getChainId();
  const provider = await getMetaMask();
  return new ethers.Contract(
    contractDetails[token].address[chainId],
    contractDetails[token].abi,
    provider
  );
};

const writeContractFunction = async (token) => {
  const mProviderInner = getMetaMask();
  const signer = await mProviderInner.getSigner();
  const chainId = await getChainId();
  return new ethers.Contract(
    contractDetails[token].address[chainId],
    contractDetails[token].abi,
    signer
  );
};

const getContractAddress = async (token) => {
  try {
    const chainId = await getChainId();
    return contractDetails[token].address[chainId];
  } catch (error) {
    console.log(error);
  }
};

const getAddress = async () => {
  try {
    const accountDetail = await getAccountDetails();
    return accountDetail.account;
  } catch (error) {
    console.log(error);
  }
};

const getEtherBalance = async () => {
  try {
    const accountDetail = await getAccountDetails();
    return accountDetail.balance;
  } catch (error) {
    console.log(error);
  }
};

const balanceOf = async (token) => {
  try {
    const readFunction = await readContractFunction(token);
    const balance = await readFunction.balanceOf(await getAddress());
    const decimal = await readFunction.decimals();
    const formatedBalance = ethers.utils.formatUnits(balance, decimal);
    return parseFloat(formatedBalance);
  } catch (error) {
    console.log(error);
  }
};

const marketPriceFn = async (usmContract) => {
  try {
    const marketPrice = await usmContract.latestPrice();
    return ethers.utils.formatUnits(marketPrice, 8);
    // return Number(marketPrice) / 10e17;
  } catch (error) {
    console.log(error);
  }
};

const marketPriceWei = async () => {
  try {
    const usmContract = await readContractFunction("usm");
    const marketPrice = await usmContract.latestPrice();
    return marketPrice;
  } catch (error) {
    console.log(error);
  }
};

const totalSupply = async (contract) => {
  try {
    const totalSupply = await contract.totalSupply();
    return totalSupply;
  } catch (error) {
    console.log(error);
  }
};

const ethPoolFn = async (contract) => {
  try {
    const ethPool = await contract.wbtcPool();
    return ethPool;
  } catch (error) {
    console.log(error);
  }
};

const fetchAllInitialValues = async () => {
  try {
    const usmContract = await readContractFunction("usm");
    const marketPrice = await marketPriceFn(usmContract);
    let ethPool = await ethPoolFn(usmContract);
    let totalSupplyUsm = await totalSupply(usmContract);
    const decimal = await usmContract.decimals();
    totalSupplyUsm = ethers.utils.formatUnits(totalSupplyUsm, decimal);
    ethPool = ethers.utils.formatUnits(ethPool, decimal);
    const tvlVal = ethPool * marketPrice;
    return { marketPrice, totalSupplyUsm, ethPool, tvlVal };
  } catch (error) {
    console.log(error);
  }
};

const fetchInitialAccVal = async () => {
  try {
    const usmBalance = await balanceOf("usm");
    const etherBalance = await getEtherBalance();
    return { usmBalance, etherBalance };
  } catch (error) {
    console.log("error in fetch");
  }
};

const getTCR = async () => {
  try {
    const contract = await writeContractFunction("vaultManager");
    const usmContract = await readContractFunction("usm");
    let marketPrice = await usmContract.latestPrice();
    let tcr = await contract.getTCR(marketPrice);
    tcr = ethers.utils.formatUnits(tcr, 8) * 100;
    if (Number(tcr) === Number(bigNumber)) {
      return { tcr: null };
    }
    return { tcr };
  } catch (error) {
    console.log("err-----------", error);
  }
};

const sortedVaultSize = async () => {
  try {
    const contract = await readContractFunction("sortedVaults");
    const size = await contract.getSize();
    const vaultSize = size.toString();
    return { vaultSize };
  } catch (error) {
    console.log(error);
  }
};

const changeMetaMaskNetwork = async () => {
  try {
    await window.ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: "0xaa36a7" }],
    });
  } catch (switchError) {
    // This error code indicates that the chain has not been added to MetaMask.
    if (switchError.code === 4902) {
      try {
        await window.ethereum.request({
          method: "wallet_addEthereumChain",
          params: [
            {
              chainId: "0xaa36a7",
              chainName: "...",
              rpcUrls: ["https://..."] /* ... */,
            },
          ],
        });
      } catch (addError) {
        // handle "add" error
      }
    }
    // handle other "switch" errors
  }
};
const getAllowance = async (address, wbtcValue) => {
  const chainId = await getChainId();
  const provider = await getMetaMask();
  const usdaoAddress = contractDetails["usm"].address[chainId];
  try {
    const wbtc = await readContractFunction("wbtc");
    const writeWbtc = await writeContractFunction("wbtc");
    const getAllow = await wbtc.allowance(address, usdaoAddress);
    if (getAllow <= wbtcValue) {
      const approveToken = await writeWbtc.approve(usdaoAddress, wbtcValue);
      return approveToken;
    }
  } catch (err) {
    console.log(err);
    return false;
  }
};

const supplyUsdaoFunc = async (amount) => {
  try {
    const address = await getAddress();
    const provider = await getMetaMask();
    const chainId = await getChainId();
    const amnt = ethers.utils.parseUnits(amount, 8);
    const contract = await readContractFunction("usm");
    const cometAddress = contractDetails["Comet"].address[chainId];
    const usmAddress = contractDetails["usm"].address[chainId];
    const writeUsm = await writeContractFunction("usm");
    const getAllow = await contract.allowance(address, cometAddress);
    if (getAllow <= amnt) {
      const approveToken = await writeUsm.approve(cometAddress, amnt);
      let waitFortrnc = await provider.waitForTransaction(approveToken.hash);
      if (waitFortrnc) {
        const writeComet = await writeContractFunction("Comet");
        const value = await writeComet.supply(usmAddress, amnt);
        if (value) return true;
      }
    }
    // else {
    //   swal("You don't have Enough Amount");
    //   return false;
    // }
  } catch (e) {
    console.log(e);
  }
};

const supplyEthFunc = async (amount) => {
  // try {
  console.log("check");
  const address = await getAddress();
  const provider = getMetaMask();
  const chainId = await getChainId();
  const amnt = ethers.utils.parseUnits(amount, 18);
  const contract = await readContractFunction("wETH");
  const cometAddress = contractDetails["Comet"].address[chainId];
  const wethAddress = contractDetails["wETH"].address[chainId];
  const writeWeth = await writeContractFunction("wETH");
  const getAllow = await contract.allowance(address, cometAddress);
  // if (getAllow >= amnt) {
  const checkBalweth = await contract.balanceOf(address);
  if (ethers.utils.formatUnits(checkBalweth, 18) >= amnt) {
    const approveToken = await writeWeth.approve(cometAddress, amnt);
    let waitFortrnc = await provider.waitForTransaction(approveToken.hash);
    try {
      if (waitFortrnc) {
        const writeComet = await writeContractFunction("Comet");
        const value = await writeComet.supply(wethAddress, amnt);
        const val = await provider.waitForTransaction(value.hash);
        if (val) return true;
      } else {
        return false;
      }
    } catch (e) {
      console.log(e);
      return false;
    }
  } else {
    swal("You don't have Enough Balance");
    return false;
  }
  // } catch (e) {
  //   console.log("====>", e);
  //   return false;
  // }
};

const supplyLinkFunc = async (amount) => {
  try {
    const address = await getAddress();
    const provider = await getMetaMask();
    const chainId = await getChainId();
    const amnt = ethers.utils.parseUnits(amount, 18);
    const contract = await readContractFunction("LINK");
    const cometAddress = contractDetails["Comet"].address[chainId];
    const wbtcAddress = contractDetails["LINK"].address[chainId];
    const writeWbtc = await writeContractFunction("LINK");
    const getAllow = await contract.allowance(address, cometAddress);
    // if (getAllow > amnt) {
    const approveToken = await writeWbtc.approve(cometAddress, amnt);
    let waitFortrnc = await provider.waitForTransaction(approveToken.hash);
    if (waitFortrnc) {
      const writeComet = await writeContractFunction("Comet");
      const value = await writeComet.supply(wbtcAddress, amnt);
      if (value) return true;
    }
    // } else {
    //   swal("You don't have Enough Amount");
    //   return false;
    // }
  } catch (e) {
    console.log(e);
  }
};

const supplyWbtcFunc = async (amount) => {
  try {
    const address = await getAddress();
    const provider = await getMetaMask();
    const chainId = await getChainId();
    const amnt = ethers.utils.parseUnits(amount, 8);
    const contract = await readContractFunction("wbtc");
    const cometAddress = contractDetails["Comet"].address[chainId];
    const wbtcAddress = contractDetails["wbtc"].address[chainId];
    const writeWbtc = await writeContractFunction("wbtc");
    const checkBalwbtc = await contract.balanceOf(address);
    if (
      ethers.utils.formatUnits(checkBalwbtc, 8) >=
      ethers.utils.formatUnits(amnt, 8)
    ) {
      // const getAllow = await contract.allowance(address, cometAddress);
      // if (getAllow <= amnt) {
      const approveToken = await writeWbtc.approve(cometAddress, amnt);
      let waitFortrnc = await provider.waitForTransaction(approveToken.hash);
      if (waitFortrnc) {
        const writeComet = await writeContractFunction("Comet");
        const value = await writeComet.supply(wbtcAddress, amnt);
        if (value) return true;
      }
      // } else {
      //   swal("You don't have Enough Amount");
      //   return false;
      // }
    } else {
      swal("You don't have Enough Balance");
      return false;
    }
  } catch (e) {
    console.log(e);
  }
};

const borrowUsdaoFunc = async (amount) => {
  try {
    const chainId = await getChainId();
    const amnt = ethers.utils.parseUnits(amount, 8);
    const usdaoAddress = contractDetails["usm"].address[chainId];
    const writeComet = await writeContractFunction("Comet");
    const value = await writeComet.withdraw(usdaoAddress, amnt);
    if (value) return true;
  } catch (e) {
    console.log(e);
  }
};

const borrowChainFunc = async (amount) => {
  try {
    const chainId = await getChainId();
    const amnt = ethers.utils.parseUnits(amount, 18);
    const usdaoAddress = contractDetails["LINK"].address[chainId];
    const writeComet = await writeContractFunction("Comet");
    const value = await writeComet.withdraw(usdaoAddress, amnt);
    if (value) return true;
  } catch (e) {
    console.log(e);
  }
};

const borrowEthFunc = async (amount) => {
  try {
    const chainId = await getChainId();
    const amnt = ethers.utils.parseUnits(amount, 18);
    const usdaoAddress = contractDetails["wETH"].address[chainId];
    const writeComet = await writeContractFunction("Comet");
    const value = await writeComet.withdraw(usdaoAddress, amnt);
    if (value) return true;
  } catch (e) {
    console.log(e);
  }
};

const borrowWbtcFunc = async (amount) => {
  try {
    const chainId = await getChainId();
    const amnt = ethers.utils.parseUnits(amount, 8);
    const usdaoAddress = contractDetails["wbtc"].address[chainId];
    const writeComet = await writeContractFunction("Comet");
    const value = await writeComet.withdraw(usdaoAddress, amnt);
    if (value) return true;
  } catch (e) {
    console.log(e);
  }
};

const balanceOfComet = async () => {
  try {
    const readFunction = await readContractFunction("Comet");
    const balance = await readFunction.balanceOf(await getAddress());
    const decimal = await readFunction.decimals();
    const formatedBalance = ethers.utils.formatUnits(balance, decimal);
    return parseFloat(formatedBalance);
  } catch (error) {
    console.log(error);
  }
};

const lendingBalChainLink = async () => {
  try {
    let { address } = getAccount();
    const chainId = await getChainId();
    const readFunction = await readContractFunction("CometExt");
    const colBal = await readFunction.collateralBalanceOf(
      address,
      contractDetails["LINK"].address[chainId]
    );
    const bal = ethers.utils.formatUnits(colBal, 18);
    return bal;
  } catch (error) {
    console.log(error);
  }
};
const lendingBalWbtc = async () => {
  try {
    let { address } = getAccount();
    const chainId = await getChainId();
    const readFunction = await readContractFunction("CometExt");
    const colBal = await readFunction.collateralBalanceOf(
      address,
      contractDetails["wbtc"].address[chainId]
    );
    const bal = ethers.utils.formatUnits(colBal, 8);
    return bal;
  } catch (error) {
    console.log(error);
  }
};
const lendingBalWeTH = async () => {
  try {
    let { address } = getAccount();
    const chainId = await getChainId();
    const readFunction = await readContractFunction("CometExt");
    const colBal = await readFunction.collateralBalanceOf(
      address,
      contractDetails["wETH"].address[chainId]
    );
    const bal = ethers.utils.formatUnits(colBal, 18);
    return bal;
  } catch (error) {
    console.log(error);
  }
};

const borrowRate = async () => {
  try {
    const readFunction = await readContractFunction("Comet");
    const utilization = await readFunction.getUtilization();
    const borrow = await readFunction.getBorrowRate(utilization);
    const bal = borrow.mul(31536000);
    const formatBal = ethers.utils.formatUnits(bal, 18);
    return (Number(formatBal) * 100).toFixed(2);
  } catch (error) {
    console.log(error);
  }
};

const supplyRate = async () => {
  try {
    const readFunction = await readContractFunction("Comet");
    const utilization = await readFunction.getUtilization();
    const borrow = await readFunction.getSupplyRate(utilization);
    const bal = borrow.mul(31536000);
    const formatBal = ethers.utils.formatUnits(bal, 18);
    return (Number(formatBal) * 100).toFixed(2);
  } catch (error) {
    console.log(error);
  }
};

const collateralValue = async () => {
  try {
    let { address } = getAccount();
    const chainId = await getChainId();
    const readFunction = await readContractFunction("CometExt");
    const readFunctionOne = await readContractFunction("Comet");
    const colBalWbtc = await readFunction.collateralBalanceOf(
      address,
      contractDetails["wbtc"].address[chainId]
    );
    const wbtcPrice = await readFunctionOne.getPrice(
      contractDetails["wbtc"].chainLinkAddress[chainId]
    );

    const wbtcusdBign = colBalWbtc.mul(wbtcPrice);
    const wbtcUsd = Number(ethers.utils.formatUnits(wbtcusdBign, 16)).toFixed(
      2
    );

    const colBallink = await readFunction.collateralBalanceOf(
      address,
      contractDetails["LINK"].address[chainId]
    );
    const linkPrice = await readFunctionOne.getPrice(
      contractDetails["LINK"].chainLinkAddress[chainId]
    );
    const linkusdBign = colBallink.mul(linkPrice);
    const linkUsd = Number(ethers.utils.formatUnits(linkusdBign, 26)).toFixed(
      2
    );

    const colBalWeth = await readFunction.collateralBalanceOf(
      address,
      contractDetails["wETH"].address[chainId]
    );
    const wethPrice = await readFunctionOne.getPrice(
      contractDetails["wETH"].chainLinkAddress[chainId]
    );
    const wethusdBign = colBalWeth.mul(wethPrice);
    const wethkUsd = Number(ethers.utils.formatUnits(wethusdBign, 26)).toFixed(
      2
    );
    return Number(wbtcUsd) + Number(linkUsd) + Number(wethkUsd);
  } catch (error) {
    console.log(error);
  }
};

const totalUsd = async (address) => {
  try {
    const chainId = await getChainId();
    const readFunction = await readContractFunction("CometExt");
    const readFunctionOne = await readContractFunction("Comet");
    const colBalWbtc = await readFunction.collateralBalanceOf(
      address,
      contractDetails["wbtc"].address[chainId]
    );
    const wbtcPrice = await readFunctionOne.getPrice(
      contractDetails["wbtc"].chainLinkAddress[chainId]
    );

    const wbtcusdBign = colBalWbtc.mul(wbtcPrice);
    const wbtcUsd = Number(ethers.utils.formatUnits(wbtcusdBign, 16)).toFixed(
      2
    );

    const colBallink = await readFunction.collateralBalanceOf(
      address,
      contractDetails["LINK"].address[chainId]
    );
    const linkPrice = await readFunctionOne.getPrice(
      contractDetails["LINK"].chainLinkAddress[chainId]
    );
    const linkusdBign = colBallink.mul(linkPrice);
    const linkUsd = Number(ethers.utils.formatUnits(linkusdBign, 26)).toFixed(
      2
    );

    const colBalWeth = await readFunction.collateralBalanceOf(
      address,
      contractDetails["wETH"].address[chainId]
    );
    const wethPrice = await readFunctionOne.getPrice(
      contractDetails["wETH"].chainLinkAddress[chainId]
    );
    const wethusdBign = colBalWeth.mul(wethPrice);
    const wethkUsd = Number(ethers.utils.formatUnits(wethusdBign, 26)).toFixed(
      2
    );
    return (Number(wbtcUsd) + Number(linkUsd) + Number(wethkUsd)).toFixed(2);
  } catch (error) {
    console.log(error);
  }
};

const borrowCapacity = async () => {
  try {
    let { address } = getAccount();
    const chainId = await getChainId();
    const readFunction = await readContractFunction("CometExt");
    const readFunctionOne = await readContractFunction("Comet");
    const colBalWbtc = await readFunction.collateralBalanceOf(
      address,
      contractDetails["wbtc"].address[chainId]
    );
    const assetInfoWbtc = await readFunctionOne.getAssetInfo(2);
    const wbtcPrice = await readFunctionOne.getPrice(
      contractDetails["wbtc"].chainLinkAddress[chainId]
    );
    const wbtcusdBign = colBalWbtc.mul(wbtcPrice);
    const borrowWbtc = wbtcusdBign.mul(assetInfoWbtc.borrowCollateralFactor);
    const wbtcUsd = Number(ethers.utils.formatUnits(borrowWbtc, 34)).toFixed(2);

    const colBallink = await readFunction.collateralBalanceOf(
      address,
      contractDetails["LINK"].address[chainId]
    );
    const linkPrice = await readFunctionOne.getPrice(
      contractDetails["LINK"].chainLinkAddress[chainId]
    );
    const assetInfoLink = await readFunctionOne.getAssetInfo(1);
    const linkusdBign = colBallink.mul(linkPrice);
    const borrowLink = linkusdBign.mul(assetInfoLink.borrowCollateralFactor);
    const linkUsd = Number(ethers.utils.formatUnits(borrowLink, 44)).toFixed(2);

    const colBalWeth = await readFunction.collateralBalanceOf(
      address,
      contractDetails["wETH"].address[chainId]
    );
    const wethPrice = await readFunctionOne.getPrice(
      contractDetails["wETH"].chainLinkAddress[chainId]
    );
    const assetInfoweth = await readFunctionOne.getAssetInfo(0);
    const wethusdBign = colBalWeth.mul(wethPrice);
    const borrowWeth = wethusdBign.mul(assetInfoweth.borrowCollateralFactor);
    const wethUsd = Number(ethers.utils.formatUnits(borrowWeth, 44)).toFixed(2);
    return Number(wbtcUsd) + Number(linkUsd) + Number(wethUsd);
  } catch (error) {
    console.log(error);
  }
};

const borrowOfAvailable = async () => {
  const getVal = await borrowCapacity();
  try {
    const readFunction = await readContractFunction("Comet");
    const balance = await readFunction.borrowBalanceOf(await getAddress());
    const decimal = await readFunction.decimals();
    const formatedBalance = ethers.utils.formatUnits(balance, decimal);
    return parseFloat(Number(getVal) - formatedBalance);
  } catch (error) {
    console.log(error);
  }
};

const changeMetaMaskNetworkChain = async (chainId) => {
  const currentChainId = await getChainId();
  if (currentChainId !== chainId) {
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: Web3.utils.toHex(chainId) }],
      });
      console.log(`switched to chainid : ${chainId} succesfully`);
    } catch (err) {
      if (err.code === 4902) {
      }
    }
  }
};

const totalBorrow = async (address) => {
  try {
    const readFunction = await readContractFunction("Comet");
    const balance = await readFunction.borrowBalanceOf(address);
    const decimal = await readFunction.decimals();
    const formatedBalance = ethers.utils.formatUnits(balance, decimal);
    return parseFloat(formatedBalance).toFixed(2);
  } catch (error) {
    console.log(error);
  }
};

const borrowCapacityLiquidation = async () => {
  try {
    let { address } = getAccount();
    const chainId = await getChainId();
    const readFunction = await readContractFunction("CometExt");
    const readFunctionOne = await readContractFunction("Comet");
    const colBalWbtc = await readFunction.collateralBalanceOf(
      address,
      contractDetails["wbtc"].address[chainId]
    );
    const assetInfoWbtc = await readFunctionOne.getAssetInfo(2);
    const wbtcPrice = await readFunctionOne.getPrice(
      contractDetails["wbtc"].chainLinkAddress[chainId]
    );
    const wbtcusdBign = colBalWbtc.mul(wbtcPrice);
    const borrowWbtc = wbtcusdBign.mul(assetInfoWbtc.borrowCollateralFactor);
    const wbtcUsd = Number(ethers.utils.formatUnits(borrowWbtc, 34)).toFixed(2);

    const colBallink = await readFunction.collateralBalanceOf(
      address,
      contractDetails["LINK"].address[chainId]
    );
    const linkPrice = await readFunctionOne.getPrice(
      contractDetails["LINK"].chainLinkAddress[chainId]
    );
    const assetInfoLink = await readFunctionOne.getAssetInfo(1);
    const linkusdBign = colBallink.mul(linkPrice);
    const borrowLink = linkusdBign.mul(assetInfoLink.borrowCollateralFactor);
    const linkUsd = Number(ethers.utils.formatUnits(borrowLink, 44)).toFixed(2);

    const colBalWeth = await readFunction.collateralBalanceOf(
      address,
      contractDetails["wETH"].address[chainId]
    );
    const wethPrice = await readFunctionOne.getPrice(
      contractDetails["wETH"].chainLinkAddress[chainId]
    );
    const assetInfoweth = await readFunctionOne.getAssetInfo(0);
    const wethusdBign = colBalWeth.mul(wethPrice);
    const borrowWeth = wethusdBign.mul(assetInfoweth.borrowCollateralFactor);
    const wethUsd = Number(ethers.utils.formatUnits(borrowWeth, 44)).toFixed(2);
    return Number(wbtcUsd) + Number(linkUsd) + Number(wethUsd);
  } catch (error) {
    console.log(error);
  }
};

const liquidationPoint = async (address) => {
  let totalBorrows = await totalBorrow(address);
  try {
    const readFunction = await readContractFunction("Comet");
    const assetInfoWbtc = await readFunction.getAssetInfo(2);
    const assetInfoLink = await readFunction.getAssetInfo(0);
    const assetInfoWeth = await readFunction.getAssetInfo(1);
    const wbtcLCF = assetInfoWbtc.liquidateCollateralFactor;
    const linkLCF = assetInfoLink.liquidateCollateralFactor;
    const wethLCF = assetInfoWeth.liquidateCollateralFactor;
    const averageLCF =
      (wbtcLCF / 10 ** 18 + linkLCF / 10 ** 18 + wethLCF / 10 ** 18) / 3;
    const liquidationPoint = totalBorrows / averageLCF;
    return liquidationPoint.toFixed(2);
  } catch (error) {
    console.log(error);
  }
};

const isLiquidation = async (address) => {
  try {
    const readFunction = await readContractFunction("Comet");
    const liqudatable = await readFunction.isLiquidatable(address);
    return liqudatable;
  } catch (err) {
    console.log(err);
  }
};

const absorbUser = async (addressUser, address) => {
  const writeFunction = await writeContractFunction("Comet");
  const absorbUsers = await writeFunction.absorb(addressUser, address);
  console.log(absorbUsers);
};

export {
  getMetaMask,
  getAccountDetails,
  getChainId,
  getNetworkName,
  readContractFunction,
  writeContractFunction,
  getContractAddress,
  getAddress,
  getEtherBalance,
  balanceOf,
  fetchAllInitialValues,
  fetchInitialAccVal,
  getTCR,
  marketPriceWei,
  marketPriceFn,
  sortedVaultSize,
  changeMetaMaskNetwork,
  getAllowance,
  supplyUsdaoFunc,
  supplyEthFunc,
  supplyLinkFunc,
  supplyWbtcFunc,
  borrowUsdaoFunc,
  borrowEthFunc,
  borrowWbtcFunc,
  borrowChainFunc,
  balanceOfComet,
  lendingBalChainLink,
  lendingBalWbtc,
  lendingBalWeTH,
  borrowRate,
  supplyRate,
  collateralValue,
  borrowCapacity,
  borrowOfAvailable,
  changeMetaMaskNetworkChain,
  totalUsd,
  totalBorrow,
  liquidationPoint,
  isLiquidation,
  absorbUser,
};
