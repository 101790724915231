const initialState = {};

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case "setDashboardDetails":
      return { ...state, dashboardDetails: action.payload };
    case "setAccountDetails":
      return { ...state, accountDetails: action.payload };
    case "setVaultDetails":
      return { ...state, vaultDetails: action.payload };
    // case "setStakeDetails":
    //   return { ...state, stakeDetails: action.payload };
    case "setTransactionLoader":
      return { ...state, transactionLoader: action.payload };
    // case 'setAssetDistribution':
    //     return {...state, assetDistribution : action.payload}
    // case 'setAssetToken':
    //     return {...state, assetToken : action.payload}
    case "setPendingRewards":
      return { ...state, pendingRewards: action.payload };
  }
  return state;
};

export default appReducer;
