import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { Col } from "react-bootstrap";
import { truncateToDecimals } from "../../../common/utils";
import add from "../../../../assets/svg/metamask.svg";
import stakeVault from "../../../../assets/svg/stack-vault.svg";
import { useNavigate } from "react-router-dom";
import { addCustomTokenMetamask } from "../helper";
import { convertToInternationalCurrencySystem } from "../../../common/utils";
import usdao from "../../../../assets/svg/usdao-icon.svg";
import { setHeader } from "../../../redux/action";

const UsdaoCard = (props) => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const selector = useSelector((state) => state);
  let { accountDetails } = ({} = selector.appReducer);

  let { stakeDetails = {}, dashboardDetails = {} } = props;

  const handleNavigateUsdao = () => {
    dispatch(setHeader("Vault"));
    navigate("/vault");
  };

  const handleNavigateStake = () => {
    dispatch(setHeader("Stake"));
    navigate("/stake");
  };

  return (
    <Col xs={12} md={12} xl={6}>
      <div className="dashboard-card">
        <div className="d-flex align-items-center justify-content-between">
          <h4 className="title-primary">USDAO</h4>
          <div className="flex d-inline-flex price-wrap">
            <button
              className=" btn card-btn-meta"
              onClick={() => addCustomTokenMetamask("usm", "USDAO")}
            >
              <img src={add} alt="" />
            </button>
            <span className="sub-title">Add to Metamask</span>
          </div>
        </div>
        <div className="row usdaosections">
          <div className="col-4 border-card-details">
            <div className="">
              <p className="card-body-name">Price</p>
              <p className="card-body-value">$ 1.00</p>
            </div>
          </div>
          <div className="col-4 border-card-details">
            <div className=" align-items-center">
              <p className="card-body-name">Total supply</p>
              <p className="card-body-value">
                {dashboardDetails.totalSupplyUsm
                  ? convertToInternationalCurrencySystem(
                      dashboardDetails.totalSupplyUsm
                    )
                  : "0.00"}
              </p>
            </div>
          </div>
          <div className="col-4">
            <div className="align-items-center justify-content-between">
              <p className="card-body-name">User Address</p>
              <p className="card-body-value">
                {accountDetails &&
                  accountDetails.account &&
                  accountDetails.account.substring(0, 2)}
                ...
                {accountDetails &&
                  accountDetails.account &&
                  accountDetails.account.substring(
                    accountDetails.account.length - 2
                  )}
              </p>
            </div>
          </div>
        </div>
        <hr className="hr-line" />
        <div className="card-footer-info">
          {/* <button className=" btn card-btn" onClick={() => addCustomTokenMetamask('usm', 'USDAO')}>
            <img src={add} alt="" />
            <p>Add</p>
          </button> */}

          <button
            className="btn card-btn "
            onClick={() => handleNavigateUsdao()}
          >
            {/* <img src={usdao} alt="" /> */}
            <p>Buy USDAO</p>
          </button>

          {/* <button
            className="btn card-btn-stake"
            onClick={() => handleNavigateStake()}
          >
            <p>Stake USDAO</p>
          </button> */}
        </div>
      </div>
    </Col>
  );
};

export default UsdaoCard;
