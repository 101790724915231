import React, { useEffect } from "react";
import "./style.scss";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  HashRouter,
} from "react-router-dom";
import Home from "../src/ui/components/Home/Home";
import Onboard from "../src/ui/components/Onboard/Onboard";
import Dashboard from "../src/ui/components/Dashboard/Dashboard";
import History from "./ui/components/RewardHistory/History";
import Vault from "../src/ui/components/Vault/Vault";
import Stake from "../src/ui/components/Stake/Stake";
import CompleteHistory from "./ui/components/RewardHistory/History";
import Liquidation from "../src/ui/components/Liquidation/Liquidation";
import LayoutWrapComp from "../src/ui/common/Layout/layoutWrapComp";
// import Chatbot from "https://cdn.jsdelivr.net/npm/flowise-embed/dist/web.js"

function App() {
  // useEffect(() => {
  //   Chatbot.init({ chatflowid: "f4853155-c069-4d6d-976d-f91044eac370",
  //   apiHost: "https://flowise-test-zad9.onrender.com",})
  // },[])
  return (
    <>
      <HashRouter hasType="hashbang">
        <React.Suspense>
          <Routes>
            <Route path="/home" name="Home" element={<Home />} />
            <Route path="*" name="Page" element={<LayoutWrapComp />} />
          </Routes>
        </React.Suspense>
      </HashRouter>
    </>
  );
}

export default App;
