import React from 'react';
import './icon-box.scss';

const IconBox = (props) => {
  return (
    <div className="icon-box">
      <div className="icon-wrap">
        <img src={props.icon} alt="icon box" />
      </div>

      <p className="icon-title">{props.iconTitle}</p>
      <p className="icon-box-content">
        {props.boxContent}
      </p>
    </div>
  );
}

export default IconBox