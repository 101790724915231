import React from "react";
import { Col } from "react-bootstrap";
import GaugeChart from "react-gauge-chart/dist/GaugeChart";
import { truncateToDecimals } from "../../../common/utils";
import { useDispatch, useSelector } from "react-redux";

const TCRCard = ({ dashboardDetails = {} }) => {
  const selector = useSelector((state) => state);
  let { theme = {} } = selector.themeReducer;
  let ds = dashboardDetails.tcr ? truncateToDecimals(dashboardDetails.tcr) : 0;

  if (ds < 100) {
    ds = ds / 100;
  } else if (ds < 1000) {
    ds = ds / 1000;
  } else if (ds < 10000) {
    ds = ds / 10000;
  } else if (ds < 100000) {
    ds = ds / 100000;
  }

  return (
    <Col xl={6} md={12} xs={12}>
      <div className="dashboard-card">
        <GaugeChart
          id="gauge-chart5"
          nrOfLevels={10}
          arcsLength={[
            2.35, 2.35, 2.35, 2.35, 2.35, 2.35, 2.35, 2.35, 2.35, 2.35,
          ]}
          colors={
            theme == "dark-theme"
              ? ["#FDD0B8", "#632807"]
              : ["#FDD0B8", "#632807"]
          }
          percent={ds}
          arcPadding={0.0}
          animate={false}
          needleColor={theme == "dark-theme" ? ["#FFFFFF"] : ["#000"]}
          needleBaseColor={theme == "dark-theme" ? ["#FFFFFF"] : ["#000"]}
          hideText={"true"}
          arcWidth={0.5}
          cornerRadius={0}
        />
        <div className="d-flex justify-content-between">
          <h4 className="title-white">Total Collateral Ratio</h4>
          <h4 className="title-white text-center mb-0">
            TCR{" "}
            {dashboardDetails && dashboardDetails.tcr
              ? truncateToDecimals(dashboardDetails.tcr)
              : "0.00"}
            %
          </h4>
        </div>
      </div>
    </Col>
  );
};

export default TCRCard;
