import React from "react";
import { Col, Row, Accordion } from "react-bootstrap";
import IconBox from "../../common/icon-box/IconBox";
import Header from "../../common/Header/Header";
import Footer from "../../common/Footer/Footer";
import "./home.scss";
import chart from "../../../assets/svg/chart.svg";
import ratio from "../../../assets/svg/ration.svg";
import dollar from "../../../assets/svg/dollar.svg";

const Home = () => {
  return (
    <>
      <Header />

      {/* =========================== begin::banner ========================= */}
      <div className="home-banner-wrap padding-x">
        <div className="banner-title-wrap">
          <p className="banner-title">
            Interest-free <span>USDAO</span> at your fingertips
          </p>

          <p className="banner-text">
            Borrow USDAO against WBTC at 0% interest.
          </p>

          <div className="banner-btn-wrap d-flex align-items-center">
            <button className="btn btn-gradient">Borrow Now</button>
            <button className="btn btn-outline">Learn More</button>
          </div>
        </div>
      </div>
      {/* =========================== end::banner ========================= */}

      {/* =========================== begin::number ========================= */}
      <div className="counter-wrap padding-x">
        <div className="counter-box-wrap">
          <div className="counter-content-wrap">
            <p className="counter-title">TOTAL VALUE LOCKED</p>
            <p className="counter-value text-center">$0</p>
          </div>
        </div>
      </div>
      {/* =========================== end::number ========================= */}

      {/* =========================== begin::featured ========================= */}
      <div className="feature-wrap padding-x">
        <h2 className="section-title">
          <span>ONVAULT</span> Feature
        </h2>
        <div className="d-flex justify-content-center">
          <p className="section-desc">
            There’s no need to create an account! Just connect your web 3.0
            wallet and get USDAO
          </p>
        </div>

        <Row className="gx-5 gy-5 justify-content-center">
          <Col md={6} lg={4}>
            <IconBox
              icon={chart}
              iconTitle={"110% Collateral Ratio*"}
              boxContent={
                "Liquity's efficient liquidation mechanism allows users to get the most liquidity for their WBTC. *Under normal operation. "
              }
            />
          </Col>
          <Col md={6} lg={4}>
            <IconBox
              icon={ratio}
              iconTitle={"110% Collateral Ratio*"}
              boxContent={
                "Liquity's efficient liquidation mechanism allows users to get the most liquidity for their WBTC. *Under normal operation. "
              }
            />
          </Col>
          <Col md={6} lg={4}>
            <IconBox
              icon={dollar}
              iconTitle={"110% Collateral Ratio*"}
              boxContent={
                "Liquity's efficient liquidation mechanism allows users to get the most liquidity for their WBTC. *Under normal operation. "
              }
            />
          </Col>
        </Row>
      </div>
      {/* =========================== end::featured ========================= */}

      {/* =========================== begin:faq ========================= */}
      <div className="faq-section padding-x">
        <h2 className="section-title">
          <span>ONVAULT</span> FAQ
        </h2>
        <div className="d-flex justify-content-center">
          <p className="section-desc">
            There’s no need to create an account! Just connect your web 3.0
            wallet and get USDAO
          </p>
        </div>

        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              How frequently will I receive staking rewards?
            </Accordion.Header>
            <Accordion.Body>
              It is a long established fact that a reader will be distracted by
              the readable content of a page when looking at its layout. The
              point of using Lorem Ipsum is that it has a more-or-less
              normaldistribution of letters, as opposed to using 'Content here,
              tent here',making it look like readable English. Many desktop
              publishing packages and web page editors now use Lorem Ipsum as
              their efault model text, and a search
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="1">
            <Accordion.Header>
              Is there a minimum/maximum digital token holdings requirement to
              start staking?
            </Accordion.Header>
            <Accordion.Body>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum.
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="2">
            <Accordion.Header>
              Is there a minimum/maximum digital token holdings requirement to
              start staking?
            </Accordion.Header>
            <Accordion.Body>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum.
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="3">
            <Accordion.Header>
              Is there a minimum/maximum digital token holdings requirement to
              start staking?
            </Accordion.Header>
            <Accordion.Body>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum.
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
      {/* =========================== end:faq ========================= */}
      <Footer />
    </>
  );
};

export default Home;
