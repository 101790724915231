const initialState = {
  header: "Dashboard",
};

const headerReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_HEADER_NAME":
      return { ...state, header: action.payload };

    default:
      return state;
  }
};

export default headerReducer;
