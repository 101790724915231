import React from "react";
import { Col } from "react-bootstrap";
import { truncateToDecimals } from "../../../common/utils";

const WalletCard = ({ dashboardDetails = {}, accountDetails = {} }) => {
  return (
    <Col xl={12} md={12} xs={12}>
      <div className="dashboard-card">
        <div className="d-flex align-items-center justify-content-between">
          <h4 className="title-white">
            My Wallet{" "}
            <svg
              width="20"
              height="18"
              viewBox="0 0 20 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15.7689 5.3818H20C20 1.98459 17.9644 0 14.5156 0H5.48444C2.03556 0 0 1.98459 0 5.33847V12.6615C0 16.0154 2.03556 18 5.48444 18H14.5156C17.9644 18 20 16.0154 20 12.6615V12.3495H15.7689C13.8052 12.3495 12.2133 10.7975 12.2133 8.883C12.2133 6.96849 13.8052 5.41647 15.7689 5.41647V5.3818ZM15.7691 6.87244H19.2536C19.6659 6.87244 20.0002 7.19836 20.0002 7.60041V10.131C19.9954 10.5311 19.6639 10.8543 19.2536 10.859H15.8491C14.855 10.872 13.9857 10.2084 13.7602 9.26435C13.6473 8.67832 13.8058 8.07359 14.1933 7.61224C14.5808 7.15089 15.1576 6.8801 15.7691 6.87244ZM15.9207 9.53302H16.2496C16.6718 9.53302 17.014 9.19934 17.014 8.78771C17.014 8.37609 16.6718 8.04241 16.2496 8.04241H15.9207C15.7188 8.04009 15.5243 8.11668 15.3807 8.25508C15.2371 8.39348 15.1562 8.58217 15.1562 8.77905C15.1562 9.19209 15.4971 9.52827 15.9207 9.53302ZM4.7371 5.38185H10.3815C10.8037 5.38185 11.146 5.04816 11.146 4.63654C11.146 4.22492 10.8037 3.89124 10.3815 3.89124H4.7371C4.31836 3.89121 3.97753 4.21964 3.97266 4.62787C3.97263 5.04092 4.31348 5.3771 4.7371 5.38185Z"
                fill="#D1D1D1"
              />
            </svg>
          </h4>

          {/* <button className="btn card-btn">Cerate Wallet</button> */}
        </div>

        <div className="d-flex align-items-center justify-content-between card-body-row">
          <div className="col-6 border-card-details">
            <p className=" card-body-name wallte-body-name mb-2">USDAO</p>

            <p className="card-body-value wallte-body-value">
              {accountDetails.usmBalance
                ? truncateToDecimals(accountDetails.usmBalance)
                : "0.00"}
            </p>
          </div>
          <div className="col-6 ps-4">
            <p className=" card-body-name wallte-body-name mb-2">
              WBTC balance
            </p>

            <p className="card-body-value wallte-body-value">
              {accountDetails.etherBalance
                ? truncateToDecimals(accountDetails.etherBalance)
                : "0.00"}
            </p>
          </div>
        </div>
      </div>
    </Col>
  );
};

export default WalletCard;
