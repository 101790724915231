import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import ReactSwitch from "react-switch";
import bg from "../../../assets/banner/dashboard-bg.png";
import logo from "../../../assets/svg/logo.svg";
import meta from "../../../assets/svg/metamask.svg";
import arrow from "../../../assets/svg/Vector.svg";
import usdao from "../../../assets/svg/usdao-logo.svg";
import onvault from "../../../assets/svg/onvaultnew.svg";
import onvault_light from "../../../assets/svg/logo-light.png";
import { Link, NavLink } from "react-router-dom";
import { setAccountDetails } from "../../redux/action";
import { TailSpin } from "react-loader-spinner";
import profile from "../../../assets/img/profile.svg";
import dashboard from "../../../assets/icons/dashboard.svg";
import dashboard_light from "../../../assets/icons/dashboard_light.svg";
import dashboardFill from "../../../assets/icons/dashboard-fill.svg";
import liquidation from "../../../assets/icons/liquidation.svg";
import liquidation_light from "../../../assets/icons/Liquidation-light.svg";
import liquidationFill from "../../../assets/icons/liquidation-fill.svg";
import bell from "../../../assets/icons/bell.svg";
import bell_fill from "../../../assets/icons/bell-fill.svg";
import vault from "../../../assets/icons/vault.svg";
import vault_light from "../../../assets/icons/vault_light.svg";
import vaultFill from "../../../assets/icons/vault-fill.svg";
import stake from "../../../assets/icons/stack.svg";
import stake_light from "../../../assets/icons/stack_light.svg";
import stakeFill from "../../../assets/icons/stack-fill.svg";
import circle from "../../../assets/icons/circle.svg";
import circle_light from "../../../assets/icons/circle_light.svg";
import circleFill from "../../../assets/icons/circle-fill.svg";
import Rewards from "../../../assets/icons/rewards.svg";
import Rewards_light from "../../../assets/icons/rewards_light.svg";
import RewardsFill from "../../../assets/icons/rewards-fill.svg";
import history from "../../../assets/icons/vault-history.svg";
import history_light from "../../../assets/icons/vault-history_light.svg";
import historyFill from "../../../assets/icons/vault-history-fill.svg";
import leading from "../../../assets/icons/leading.png";
import leadingG from "../../../assets/icons/leading-grey.svg";
import borrowing from "../../../assets/icons/borrowing.svg";
import docs from "../../../assets/icons/docs.svg";
import chain from "../../../assets/icons/eth-logo.png";
import docs_light from "../../../assets/icons/docs_light.svg";
import loader from "../../../assets/loader/loader.gif";
import metamask from "../../../assets/MetaMask_Fox.svg.png";
import docsFill from "../../../assets/icons/docs-fill.svg";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { Loading } from "react-loading-dot";
import * as PushAPI from "@pushprotocol/restapi";
import { MdOutlineContentCopy } from "react-icons/md";
import { IoIosArrowDown } from "react-icons/io";
import downArrow from "../../../assets/svg/down.svg";
import {
  getAccountDetails,
  fetchInitialAccVal,
  fetchAllInitialValues,
  getTCR,
  changeMetaMaskNetworkChain,
} from "../../blockchain/commonFunction";
import { truncateToDecimals } from "../utils";
import {
  faTwitter,
  faDiscord,
  faTelegram,
  faGithub,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getAccount } from "@wagmi/core";
import { setTheme, setHeader } from "../../redux/action";
import "./layout.scss";
import NotificationPage from "../../components/notification/NotificationPage";

const LoadingM = () => (
  <>
    {/* <img src={onvault} alt="" className="logo" /> */}
    <div className="img-wrap">
      <img src={loader} alt="this slowpoke moves" width="350" />
    </div>

    {/* <h4>Loading...</h4> */}
  </>
);

const NetworkType = () => (
  <>
    <img src={logo} alt="" className="logo" />
    <div className="img-wrap">
      <img src={loader} alt="this slowpoke moves" width="250" />
    </div>
    <div className="connect-rinkeby">
      Please connect to Sepolia Account/Wallet.
    </div>
  </>
);

const MetaMaskLogOut = ({ metaMask }) => {
  const handleMeta = async () => {
    await metaMask();
  };
  return (
    <>
      <div>
        <p>Connect a wallet</p>
      </div>
      <hr />
      <div className="meta-button">
        <div
          onClick={() => handleMeta()}
          className="row meta-connect d-flex align-items-center"
        >
          <div className="col-2">
            <img src={meta} alt="" className="meta-logo" />
          </div>
          <div className="col-8">
            <p>Meta Mask</p>
          </div>
          <div className="col-2">
            <img src={arrow} alt="" className="meta-logo-arrow" />
          </div>
        </div>
      </div>
      <br />
      <div className="row d-flex align-items-center">
        <div className="col-6">Don’t have MetaMask?</div>
        <div className="col-6 _link">
          <a target="_blank" href="https://metamask.io/download/">
            Get Metamask
          </a>
        </div>
      </div>
    </>
  );
};

const Modal = ({
  alertType,
  alertNetworkType,
  alertMetaMaskLogOut,
  metaMask,
}) => {
  return (
    <div
      className="modal"
      tabIndex="-1"
      role="dialog"
      data-keyboard="false"
      data-backdrop="false"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="wrapper">
            {alertNetworkType ? (
              <NetworkType />
            ) : alertMetaMaskLogOut ? (
              <MetaMaskLogOut metaMask={metaMask} />
            ) : (
              alertType === "loading" && <LoadingM />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const Layout = ({
  setToggleSidebar,
  alertNetworkType,
  toggleSidebar,
  alertType,
  setAlertType,
  alertMetaMaskLogOut,
  setAlertMetaMaskLogOut,
  metaMask,
}) => {
  const [heading, setHeading] = useState("Dashboard");
  const ref = useRef();
  const dispatch = useDispatch();
  const selector = useSelector((state) => state);
  let { accountDetails = {}, transactionLoader = false } = selector.appReducer;
  let { theme = {} } = selector.themeReducer;
  let { header = {} } = selector.headerReducer;
  const [copyStatus, setCopyStatus] = useState(false);
  const [show, setShow] = useState(false);
  const [feed, setFeed] = useState([]);
  const [dropDown, setDropDown] = useState(false);

  useEffect(() => {
    (async function () {
      await changeMetaMaskNetworkChain();
    })();
  }, []);

  if (window.ethereum) {
    window.ethereum.on("accountsChanged", async (_) => {
      window.location.reload();
      await getAccountDetails(setAlertMetaMaskLogOut);
    });
    window.ethereum.on("chainChanged", (_) => window.location.reload());
  }

  function sidebar() {
    setToggleSidebar(!toggleSidebar);
  }

  const copyAddress = () => {
    if (!navigator.clipboard) {
      return;
    }
    setCopyStatus(true);
    navigator.clipboard.writeText(accountDetails.account);
  };
  useEffect(() => {
    let modal = document.querySelector(".modal");
    let transactionDot = document.querySelector(".transaction-load");

    if (transactionDot || modal) {
      let mainEl = document.querySelector(".dashboard-wrap");
      mainEl && mainEl.classList.add("pointer-event-none");
    } else {
      let mainEl = document.querySelector(".dashboard-wrap");
      mainEl && mainEl.classList.remove("pointer-event-none");
    }
  });

  const handleSize = () => {
    if (window.innerWidth < 1030) {
      setToggleSidebar(!toggleSidebar);
    } else {
      setToggleSidebar(true);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleSize);
  }, []);

  const handleTheme = () => {
    if (theme == "dark-theme") {
      dispatch(setTheme("light-theme"));
    } else {
      dispatch(setTheme("dark-theme"));
    }
  };

  const handleChangeHeader = (val) => {
    handleSize();
    dispatch(setHeader(val));
  };

  useEffect(() => {
    document.body.className = theme;
  }, [theme]);

  const loadNotifications = async () => {
    try {
      const { address } = getAccount();
      const feeds = await PushAPI.user
        .getFeeds({
          user: `${address}`,
          limit: 30,
          env: "staging",
        })
        .catch((e) => {
          console.log(e);
        });
      if (feeds === undefined) {
        setFeed([]);
      } else {
        setFeed(feeds);
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    (async () => {
      await loadNotifications();
    })();
  }, []);

  useEffect(() => {
    const checkIfClickOutSide = (e) => {
      if (
        ref.current &&
        !ref.current.contains(e.target) &&
        e.target.className !== "dropDownMenu" &&
        e.target.className !== "arrowDown act"
      ) {
        setDropDown(false);
      }
    };
    document.addEventListener("click", checkIfClickOutSide);
    return () => {
      document.removeEventListener("click", checkIfClickOutSide);
    };
  }, []);

  const showNotification = () => {
    setShow(!show);
  };

  const handleChainArrow = () => {
    setDropDown(!dropDown);
  };

  return (
    <div>
      {/* <div className="dashboard-bg">
        <img src={bg} alt="" />
      </div> */}

      <div
        className={
          toggleSidebar ? "dashboard-layout" : "dashboard-layout active"
        }
      >
        <div className="dashboard-header">
          <div className="header-leftside">
            <button className="sidebar-btn" onClick={sidebar}>
              <svg
                className="icon"
                width="19"
                height="18"
                viewBox="0 0 25 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  className="barTop"
                  y="0.5"
                  width="16"
                  height="3"
                  rx="1.5"
                  fill="#E85F14"
                />
                <rect
                  className="bar"
                  y="10.5"
                  width="25"
                  height="3"
                  rx="1.5"
                  fill="#E85F14"
                />
                <rect
                  className="barBottom"
                  y="20.5"
                  width="16"
                  height="3"
                  rx="1.5"
                  fill="#E85F14"
                />
              </svg>
            </button>
            <Link to="/dashboard">
              <div
                onClick={() => dispatch(setHeader("Dashboard"))}
                className="dashboard-title"
              >
                <div className="title">{header}</div>
              </div>
            </Link>

            {/* <button className="sidebar-btn" onClick={sidebar}>
              <svg
                width="20"
                height="16"
                viewBox="0 0 14 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.11479 8.97107C7.11479 9.39711 6.76941 9.74249 6.34336 9.74249H1.71479C1.28874 9.74249 0.943359 9.39711 0.943359 8.97107V8.97107C0.943359 8.54502 1.28874 8.19964 1.71479 8.19964H6.34336C6.76941 8.19964 7.11479 8.54502 7.11479 8.97107V8.97107ZM13.2862 5.11392C13.2862 5.53997 12.9408 5.88535 12.5148 5.88535H1.71479C1.28874 5.88535 0.943359 5.53997 0.943359 5.11392V5.11392C0.943359 4.68788 1.28874 4.34249 1.71479 4.34249H12.5148C12.9408 4.34249 13.2862 4.68788 13.2862 5.11392V5.11392ZM13.2862 1.25678C13.2862 1.68283 12.9408 2.02821 12.5148 2.02821H7.88622C7.46017 2.02821 7.11479 1.68283 7.11479 1.25678V1.25678C7.11479 0.830732 7.46017 0.485352 7.88622 0.485352H12.5148C12.9408 0.485352 13.2862 0.830732 13.2862 1.25678V1.25678Z"
                  fill="white"
                />
              </svg>
            </button> */}
          </div>
          <div className="header-rightside">
            <div className="ChainSwitch">
              <div className="ChainSwitchwrap">
                <div className="chainDisp">
                  <div className="chainimg">
                    <img src={chain} alt="" />
                  </div>
                  <div
                    onClick={() => handleChainArrow()}
                    className="chainArrow"
                  >
                    <img
                      className={!dropDown ? "arrowDown" : "arrowDown act"}
                      src={downArrow}
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="currency-wrap">
              <div className="bell-space" onClick={() => showNotification()}>
                <img
                  className="bell"
                  src={theme === "dark-theme" ? bell : bell_fill}
                  alt="bell"
                />
              </div>
            </div>
            <div className="d-flex align-items-center pe-4">
              <ReactSwitch
                onChange={() => handleTheme()}
                checked={theme === "dark-theme" ? false : true}
                offColor="#888"
                onColor="#E85F14"
              />
            </div>
            {/* <div className="btn-wallet"> */}

            <div className="currency-btn">
              {/* <div className="currency-wrap">
                <p className="eth">
                  {(accountDetails &&
                    accountDetails.balance &&
                    truncateToDecimals(accountDetails.balance)) ||
                    "0.00"}
                  ETH
                </p>
              </div> */}

              <div
                className="wallet-currency-wrap copy-address"
                onClick={copyAddress}
                onMouseMove={() => setCopyStatus(false)}
              >
                <p>
                  {accountDetails &&
                    accountDetails.account &&
                    accountDetails.account.substring(0, 4)}
                  ....
                  {accountDetails &&
                    accountDetails.account &&
                    accountDetails.account.substring(
                      accountDetails.account.length - 4
                    )}
                </p>

                <div className="profile-img">
                  {/* <img src={profile} alt="" /> */}
                  <div className="imageLogo">
                    <MdOutlineContentCopy className="imageLogo" />
                  </div>
                </div>
                {["bottom"].map((placement) => (
                  <OverlayTrigger
                    key={placement}
                    placement={placement}
                    overlay={
                      <Tooltip id={`tooltip-${placement}`}>
                        {copyStatus ? "Copied" : "Copy"}
                      </Tooltip>
                    }
                  >
                    <button className="btn p-0 ms-2">
                      <FontAwesomeIcon icon={faCircleInfo} />
                    </button>
                  </OverlayTrigger>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className="dashboard-sidebar">
          <div className="dashboard-icon">
            <img
              className="onvLogo"
              src={theme === "dark-theme" ? onvault : onvault_light}
            />
          </div>
          <NavLink
            to="/dashboard"
            className="dashboard-nav "
            activeclassname="active"
            onClick={() => handleChangeHeader("Dashboard")}
          >
            <div className="icon-white">
              <img src={dashboard} alt="" className="dashboard-icon-nav" />
            </div>
            <div className="icon-fill">
              <img
                src={theme === "dark-theme" ? dashboardFill : dashboardFill}
                alt=""
              />
            </div>
            Dashboard
          </NavLink>

          <NavLink
            to="/vault"
            className="dashboard-nav"
            activeclassname="active"
            onClick={() => handleChangeHeader("Vault")}
          >
            <div className="icon-white">
              <img src={vault} alt="" />
            </div>
            <div className="icon-fill">
              <img
                src={theme === "dark-theme" ? vaultFill : vaultFill}
                alt=""
              />
            </div>
            Vault
          </NavLink>
          <NavLink
            to="/liquidation"
            className="dashboard-nav"
            activeclassname="active"
            onClick={() => handleChangeHeader("Liquidation")}
          >
            <div className="icon-white">
              <img src={liquidation} alt="" />
            </div>
            <div className="icon-fill">
              <img
                src={theme === "dark-theme" ? liquidationFill : liquidationFill}
                alt=""
              />
            </div>
            Liquidation
          </NavLink>

          <NavLink
            to="/Absorb"
            className="dashboard-nav"
            activeclassname="active"
            onClick={() => handleChangeHeader("Absorb")}
          >
            <div className="icon-white">
              <img src={liquidation} alt="" />
            </div>
            <div className="icon-fill">
              <img
                src={theme === "dark-theme" ? liquidationFill : liquidationFill}
                alt=""
              />
            </div>
            Absorb
          </NavLink>

          <NavLink
            to="/vault-history"
            className="dashboard-nav"
            activeclassname="active"
            onClick={() => handleChangeHeader("Vault History")}
          >
            <div className="icon-white">
              <img src={history} alt="" />
            </div>
            <div className="icon-fill">
              <img
                src={theme === "dark-theme" ? historyFill : historyFill}
                alt=""
              />
            </div>
            Vault History
          </NavLink>
          <NavLink
            to="/leading-borrowing"
            className="dashboard-nav"
            activeclassname="active"
            onClick={() => handleChangeHeader("Lending borrowing")}
          >
            <div className="icon-white">
              <img className="leading" src={leadingG} alt="" />
            </div>
            <div className="icon-fill">
              <img
                className="leading"
                src={theme === "dark-theme" ? leading : leading}
                alt=""
              />
            </div>
            Lending Borrowing
          </NavLink>

          <a
            href="https://docs.usdao.io/usdao/"
            target="_blank"
            className="dashboard-nav"
            activeclassname="active"
          >
            <div className="icon-white">
              <img src={docs} alt="" />
            </div>
            <div className="icon-fill">
              <img src={theme === "dark-theme" ? docsFill : docsFill} alt="" />
            </div>
            Docs
          </a>
          <div className="bottom-bar">
            <p>
              OnVault powered by{" "}
              <a href="https://usdao.io/" target="_blank">
                <img src={usdao} alt="" />
              </a>
            </p>
          </div>
        </div>
      </div>

      {(alertType || alertMetaMaskLogOut || alertNetworkType) && (
        <Modal
          alertType={alertType}
          alertMetaMaskLogOut={alertMetaMaskLogOut}
          alertNetworkType={alertNetworkType}
          metaMask={metaMask}
        />
      )}
      {transactionLoader && (
        <div className="transaction-load">
          <TailSpin color="#42FBFE" height={80} width={80} />
        </div>
      )}
      <NotificationPage show={show} setShow={setShow} data={feed} />
      {dropDown ? (
        <div ref={ref} className="outerDrop">
          <div className="dropDownMenu">
            <div
              onClick={() => changeMetaMaskNetworkChain(11155111)}
              className="chainsDiff"
            >
              <div className="chainIcon">
                <img src={chain} alt="" />
              </div>
              <div className="chainName">Sepolia</div>
            </div>

            <div
              onClick={() => changeMetaMaskNetworkChain(97)}
              className="chainsDiff"
            >
              <div className="chainIcon">
                <img src={chain} alt="" />
              </div>
              <div className="chainName">
                <p>BSC</p>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default Layout;
