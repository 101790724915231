import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import TotalSupply from "../../common/TotalSupply/totalSupply";
import LiquidationTable from "./LiqTable";
import { liquidationData } from "./helper";
import "./liquidation.scss";
import { alertRecovery } from "../../common/utils";

function Liquidation({ setAlertType }) {
  const [liquidationTableData, setLiquidationTableData] = useState([]);

  const selector = useSelector((state) => state);

  let {
    dashboardDetails = {},
    vaultDetails = {},
    accountDetails,
    // stakeDetails = {},
  } = ({} = selector.appReducer);
  let { theme = {} } = selector.themeReducer;

  useEffect(() => {
    (async function () {
      // setAlertType('')
      alertRecovery(vaultDetails);
      setLiquidationTableData(await liquidationData());
    })();
  }, []);
  return (
    <div className="liqudation-padding">
      <TotalSupply
        vaultDetails={vaultDetails}
        dashboardDetails={dashboardDetails}
        showClose={false}
        theme={theme}
        accountDetails={accountDetails}
      />

      <LiquidationTable
        dashboardDetails={dashboardDetails}
        // stakeDetails={stakeDetails}
        vaultDetails={vaultDetails}
        accountDetails={accountDetails}
        liquidationTableData={liquidationTableData}
        setLiquidationTableData={setLiquidationTableData}
      />
    </div>
  );
}

export default Liquidation;
