import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col } from "react-bootstrap";
import UsdaoCard from "./Card/UsdaoCard";
import AssetCard from "./Card/AssetCard";
import StatisticsCard from "./Card/StatisticsCard";
import TCRCard from "./Card/TCRCard";
import WalletCard from "./Card/WalletCard";
import ClaimCard from "./Card/ClaimCard";
import { alertRecovery } from "../../common/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTwitter,
  faDiscord,
  faTelegram,
  faGithub,
} from "@fortawesome/free-brands-svg-icons";

import {
  setDashboardDetails,
  setVaultDetails,
  setStakeDetails,
} from "../../redux/action";
import {
  sortedVaultSize,
  fetchAllInitialValues,
  getTCR,
} from "../../blockchain/commonFunction";
import { getAssetReward, getStakedRewardVal } from "../Stake/helper";
import { showClaimFn, assetCirculationFn } from "./helper";
import "./dashboard.scss";
import {
  checkRecovery,
  getVaultDebtColl,
  getVaultStatusFn,
} from "../Vault/helper";

const Dashboard = ({ setAlertType }) => {
  const dispatch = useDispatch();
  const selector = useSelector((state) => state);
  let {
    dashboardDetails = {},
    accountDetails = {},
    vaultDetails = {},
    stakeDetails = {},
  } = selector.appReducer;

  useEffect(() => {
    (async function () {
      await alertRecovery(vaultDetails);
    })();
  }, []);
  return (
    <>
      <Row className="gy-3">
        <UsdaoCard
          stakeDetails={stakeDetails}
          dashboardDetails={dashboardDetails}
        />
        <TCRCard dashboardDetails={dashboardDetails} />

        {/* <AssetCard dashboardDetails={dashboardDetails} /> */}
        <Col>
          <Row className="gy-1">
            <StatisticsCard
              dashboardDetails={dashboardDetails}
              vaultDetails={vaultDetails}
            />
            <WalletCard
              dashboardDetails={dashboardDetails}
              accountDetails={accountDetails}
            />
          </Row>
        </Col>
        {!!dashboardDetails.showClaimBtn && (
          <ClaimCard dashboardDetails={dashboardDetails} />
        )}
      </Row>
    </>
  );
};

export default Dashboard;
