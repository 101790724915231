import React from "react";
import Leading from "../components/Leading-Boarrowing/Leading";

const Dashboard = React.lazy(() => import("../components/Dashboard/Dashboard"));
const Liquidation = React.lazy(() =>
  import("../components/Liquidation/Liquidation")
);
const Absorb = React.lazy(() => import("../components/Liquidation/Absorb"));
const Vault = React.lazy(() => import("../components/Vault/Vault"));
const Stake = React.lazy(() => import("../components/Stake/Stake"));
const RewardHistory = React.lazy(() =>
  import("../components/RewardHistory/CompRewardHistory")
);
const VaultHistory = React.lazy(() =>
  import("../components/VaultHistory/vaultHistory")
);
const asset = React.lazy(() => import("../components/AssetDistribution/Asset"));

const routes = [
  { path: "/dashboard", exact: true, name: "Dashboard", element: Dashboard },
  {
    path: "/liquidation",
    exact: true,
    name: "Liquidation",
    element: Liquidation,
  },
  {
    path: "/Absorb",
    exact: true,
    name: "Absorb",
    element: Absorb,
  },
  { path: "/asset-distribution", exact: true, name: "asset", element: asset },
  { path: "/vault", exact: true, name: "Vault", element: Vault },
  { path: "/stake", exact: true, name: "Stake", element: Stake },
  {
    path: "/reward-history",
    exact: true,
    name: "Reward history",
    element: RewardHistory,
  },
  {
    path: "/vault-history",
    exact: true,
    name: "Vault history",
    element: VaultHistory,
  },
  {
    path: "/leading-borrowing",
    exact: true,
    name: "Lending Borrowing",
    element: Leading,
  },
];

export default routes;
