export const setDashboardDetails = (payload) => ({
  type: "setDashboardDetails",
  payload,
});

export const setAccountDetails = (payload) => ({
  type: "setAccountDetails",
  payload,
});

export const setVaultDetails = (payload) => ({
  type: "setVaultDetails",
  payload,
});

// export const setStakeDetails = (payload) => ({
//   type: "setStakeDetails",
//   payload,
// });

export const setTransactionLoader = (payload) => ({
  type: "setTransactionLoader",
  payload,
});

// export const setAssetDistribution = (payload) => ({
//   type: "setAssetDistribution",
//   payload,
// });

// export const setAssetToken = (payload) => ({
//   type: "setAssetToken",
//   payload,
// });

export const setPendingRewards = (payload) => ({
  type: "setPendingRewards",
  payload,
});

export const setTheme = (theme) => {
  return {
    type: "SET_CURRENT_THEME",
    payload: theme,
  };
};

export const setHeader = (header) => {
  return {
    type: "SET_HEADER_NAME",
    payload: header,
  };
};
